import { Redirect } from 'react-router-dom';
import loadable from '../loadable/component';
import CmsApi from '@utils/api/Cms';
import Restapi from '@utils/api';
import getCampaignRoutes from './campaignRoutes';
import deprecatedRoutes from './deprecatedRoutes';
import {
  CMP_HOMEPAGE,
  CMP_BALANCE_CHECKER,
  CONTENT_PREFIX,
  CAMPAIGN_PREFIX,
  BLOG_PREFIX,
  FILTER_BESTSELLERS,
  FILTER_RECENT,
  FILTER_PREORDER,
  FILTER_ALL,
  BLOGOVERVIEW_PAGINATION_LIMIT,
  DASHBOARD_PREFIX,
} from '@constants';
import { handleFetchForProductOrGenre, fetchTop, fetchCategoryRequests } from '@utils/routes';

const getRoutes = () => {
  const HomePage = loadable(() => import('../pages/HomePage'));
  const AccountPage = loadable(() => import('../pages/AccountPage'));
  const CheckoutPage = loadable(() => import('../pages/CheckoutPage'));
  const SearchPage = loadable(() => import('../pages/SearchPage'));
  const SearchPresetPage = loadable(() => import('../pages/SearchPresetPage'));
  const EntityDetailPage = loadable(() => import('../pages/EntityDetailPage'));
  const StoresPage = loadable(() => import('../pages/StoresPage'));
  const ContentPage = loadable(() => import('../pages/ContentPage'));
  const CampaignPage = loadable(() => import('../pages/CampaignPage'));
  const BlogPage = loadable(() => import('../pages/BlogPage'));
  const BlogOverviewPage = loadable(() => import('../pages/BlogOverviewPage'));
  const BlogListPage = loadable(() => import('../pages/BlogListPage'));
  const ClangPage = loadable(() => import('../pages/ClangPage'));
  const LoginPage = loadable(() => import('../pages/LoginPage'));
  const NewPasswordPage = loadable(() => import('../pages/NewPasswordPage'));
  const TransactionPage = loadable(() => import('../pages/TransactionPage'));
  const PaymentPage = loadable(() => import('../pages/PaymentPage'));
  const CreditPaymentPage = loadable(() => import('../pages/CreditPaymentPage'));
  const DevPage = loadable(() => import('../pages/DevPage'));
  const MissingPage = loadable(() => import('../pages/MissingPage'));
  const TopPage = loadable(() => import('../pages/TopPage'));
  const SeriesPage = loadable(() => import('../pages/SeriesPage'));
  const LobbyPage = loadable(() => import('../pages/LobbyPage'));
  const BalanceCheckerPage = loadable(() => import('../pages/BalanceCheckerPage'));
  const ResetPage = loadable(() => import('../pages/ResetPage'));
  const SingleStorePage = loadable(() => import('../pages/SingleStorePage'));
  const DashboardPage = loadable(() => import('../pages/DashboardPage'));
  const DashboardItemPage = loadable(() => import('../pages/DashboardItemPage'));
  const OrderlistPage = loadable(() => import('../pages/OrderlistPage'));

  return [
    {
      path: '/',
      exact: true,
      component: HomePage,
      routeId: 'Homepage',
      requiresAuthentication: true,
      fetchInitialData: (path = '', apiUrl) => {
        const api = new CmsApi({ apiUrl });
        return [api.fetchComponent({ id: CMP_HOMEPAGE })];
      },
    },
    {
      path: '/inloggen', // B2C login
      exact: true,
      component: LoginPage,
      requiresAuthentication: true,
    },
    {
      path: '/login', // B2B login
      exact: true,
      useLayout: false,
      component: LobbyPage,
    },
    {
      path: '/bedankt/:orderId?',
      exact: true,
      component: TransactionPage,
      requiresAuthentication: true,
    },
    {
      path: [
        '/boeken-top-10',
        '/spel-speelgoed-top-10',
      ],
      exact: true,
      component: TopPage,
      requiresAuthentication: true,
      fetchInitialData: fetchTop,
    },
    {
      path: [
        '/boeken-top-10/:slug',
        '/spel-speelgoed-top-10/:slug',
        '/bestseller/:path1?/:path2?/:path3?',
        '/boeken-top-100',
      ],
      exact: true,
      component: TopPage,
      requiresAuthentication: true,
      fetchInitialData: (path, apiUrl) => fetchTop(path, apiUrl),
    },
    {
      path: [
        '/pagina/:id',
        '/cookiebeleid',
        '/privacybeleid',
        '/retourneren',
        '/contact',
        '/bestelling-en-bezorging',
        '/betalen',
        '/over-ons',
        '/clickcollect',
        '/vulnerability-disclosure',
        '/partnerprogramma',
        '/vl',
        '/wifibeleid',
      ],
      component: ContentPage,
      requiresAuthentication: true,
      fetchInitialData: (path = '', apiUrl) => {
        const id = path.split('/').pop();
        const api = new CmsApi({ apiUrl });
        return [api.fetchComponentById({ id: CONTENT_PREFIX + id })];
      },
    },
    {
      path: [
        '/campagne/:id',
        ...getCampaignRoutes(),
      ],
      component: CampaignPage,
      requiresAuthentication: true,
      routeId: 'CampaignPage',
      fetchInitialData: (path = '', apiUrl) => {
        const id = path.split('/').pop();
        const api = new CmsApi({ apiUrl });
        return [api.fetchComponentById({ id: CAMPAIGN_PREFIX + id })];
      },
    },
    {
      path: '/blog',
      exact: true,
      requiresAuthentication: true,
      component: BlogOverviewPage,
      fetchInitialData: (path = '', apiUrl) => {
        const api = new CmsApi({ apiUrl });
        return [api.fetchBlogComponents({ amount: BLOGOVERVIEW_PAGINATION_LIMIT, offset: 0 })];
      },
    },

    {
      path: '/blog/lijst',
      exact: true,
      requiresAuthentication: true,
      component: BlogListPage,
      fetchInitialData: (path = '', apiUrl) => {
        const api = new CmsApi({ apiUrl });
        return [api.fetchBlogComponents()];
      },
    },
    {
      path: [
        '/blog/:id?',
      ],
      component: BlogPage,
      requiresAuthentication: true,
      fetchInitialData: (path = '', apiUrl) => {
        const id = path.split('/').pop();
        const api = new CmsApi({ apiUrl });
        return [api.fetchComponentById({ id: BLOG_PREFIX + id })];
      },
    },
    {
      path: '/entity/:slug',
      component: EntityDetailPage,
      routeId: 'EntityDetailPage',
      requiresAuthentication: true,
      fetchInitialData: (path = '', apiUrl) => {
        const slug = path.split('/').pop();
        const entityIDArr = slug.split('-');
        const id = entityIDArr[entityIDArr.length - 1];
        const api = new CmsApi({ apiUrl });
        return [api.fetchEntityById({ id })];
      },
    },
    {
      path: [
        '/mijn-account/:section?/:subSection?',
        '/mijnbookspot/:section?', // Will be deprecated
        '/mijnbruna/:section?', // Will be deprecated
        '/INTERSHOP/web/WFS/tba-readshop_nl-Site/nl_NL/-/EUR/ViewUserAccount-Start', // A necessary evil
      ],
      component: AccountPage,
      requiresAuthentication: true,
      routeId: 'AccountPage',
    },
    {
      path: '/bestellen/:section?',
      exact: true,
      requiresAuthentication: true,
      component: OrderlistPage,
      useLayout: false,
      logoHeader: true,
    },
    {
      path: '/serie',
      exact: true,
      component: SeriesPage,
      routeId: 'SeriesPage',
      requiresAuthentication: true,
      fetchInitialData: (path = '', apiUrl) => fetchCategoryRequests({ path, apiUrl }),
    },
    {
      path: deprecatedRoutes,
      deprecated: true, // Used to redirect server side
      exact: true,
      component: Redirect, // Used to redirect client-side
      to: '/',
    },
    {
      path: [
        '/cadeaukaarten/:path1?/:path2?/:path3?',
        '/boeken/:path1?/:path2?/:path3?',
        '/engelse-boeken/:path1?/:path2?/:path3?',
        '/anderstalige-boeken/:path1?/:path2?/:path3?',
        '/ebooks/:path1?/:path2?/:path3?',
        '/hobby/:path1?/:path2?/:path3?',
        '/spel-speelgoed/:path1?/:path2?/:path3?',
        '/avi-niveau/:path1?/:path2?/:path3?',
        '/kenmerk/:path1?/:path2?/:path3?',
        '/actie/:path1?/:path2?/:path3?',
        '/promo/:path1?/:path2?/:path3?',
        '/leeftijd/:path1?/:path2?/:path3?',
        '/thema/:path1?/:path2?/:path3?',
        '/serie/:path1?/:path2?/:path3?',
        '/kantoor/:path1?/:path2?/:path3?',
        '/ean/:path1',
        '/muziek/:path1?/:path2?/:path3?',
        '/film/:path1?/:path2?/:path3?',
        '/games/:path1?/:path2?/:path3?',
        '/intekenen/:path1?/:path2?/:path3?',
      ],
      requiresAuthentication: true,
      fetchInitialData: handleFetchForProductOrGenre,
    },
    {
      path: '/winkels',
      exact: true,
      component: StoresPage,
      requiresAuthentication: true,
      fetchInitialData: (path = '', apiUrl) => {
        const restapi = new Restapi({ apiUrl });
        return [restapi.getAllStores()];
      },
    },
    {
      path: '/winkels/:city/:id',
      component: SingleStorePage,
      requiresAuthentication: true,
      fetchInitialData: (path = '', apiUrl) => {
        const restapi = new Restapi({ apiUrl });
        return [restapi.getAllStores()];
      },
    },
    {
      path: [
        '/dashboard/:channel',
      ],
      exact: true,
      component: DashboardPage,
      useLayout: false,
      logoHeader: true,
      requiresAuthentication: true,
      fetchInitialData: (path = '', apiUrl) => {
        const channel = path.split('/').pop();
        const api = new CmsApi({ apiUrl });
        return [api.fetchComponentById({ id: DASHBOARD_PREFIX + channel })];
      },
    },
    {
      path: [
        '/dashboard/:channel/:id',
      ],
      component: DashboardItemPage,
      useLayout: false,
      logoHeader: true,
      requiresAuthentication: true,
      fetchInitialData: (path = '', apiUrl) => {
        const channel = path.split('/').at(-2);
        const id = path.split('/').pop();
        const api = new CmsApi({ apiUrl });
        return [api.fetchComponentById({ id: DASHBOARD_PREFIX + channel + id })];
      },
    },
    {
      path: ['/email', '/bijwerken'],
      component: ClangPage,
      requiresAuthentication: true,
    },
    {
      path: '/nieuw-wachtwoord',
      component: NewPasswordPage,
    },
    {
      path: '/betaal-link/:orderId?',
      component: PaymentPage,
      requiresAuthentication: true,
    },
    {
      path: '/winkelmand',
      exact: true,
      routeId: 'BasketPage',
      component: CheckoutPage,
      requiresAuthentication: true,
      minimalisticLayout: true,
      useLayout: false,
    },
    {
      path: '/zoeken/:searchTerm',
      exact: true,
      component: SearchPage,
      routeId: 'SearchResults',
      requiresAuthentication: true,
    },
    {
      path: [
        `/${FILTER_BESTSELLERS}/:categoryId?`,
        `/${FILTER_RECENT}/:categoryId?`,
        `/${FILTER_PREORDER}/:categoryId?`,
        `/${FILTER_ALL}/:categoryId?`,
      ],
      component: SearchPresetPage,
      routeId: 'SearchPresetPage',
      requiresAuthentication: true,
      fetchInitialData: (path = '', apiUrl) => {
        const pathArray = path.split('/');
        pathArray.splice(0, 2);
        const categoryPath = pathArray.join('/');
        return fetchCategoryRequests({ path: categoryPath, apiUrl });
      },
    },
    {
      path: '/betaal-boekentegoed',
      exact: true,
      component: CreditPaymentPage,
      requiresAuthentication: true,
    },
    {
      path: '/saldochecker',
      exact: true,
      component: BalanceCheckerPage,
      requiresAuthentication: true,
      fetchInitialData: (path = '', apiUrl) => {
        const api = new CmsApi({ apiUrl });
        return [api.fetchComponentById({ id: CMP_BALANCE_CHECKER })];
      },
    },
    {
      path: '/404',
      exact: true,
      component: MissingPage,
      requiresAuthentication: true,
    },
    {
      path: '/developer',
      exact: true,
      component: DEVELOPMENT ? DevPage : MissingPage,
      requiresAuthentication: true,
    },
    {
      path: '/reset',
      exact: true,
      component: ResetPage,
      requiresAuthentication: false,
    },
    {
      path: '*',
      component: MissingPage,
      requiresAuthentication: true,
    },
  ];
};

export default getRoutes;
